/* eslint-disable quotes */
import dayjs from 'dayjs';

export default {
  NICK_NICKELS: {
    fullName: 'Nick Nickels',
    image: '/assets/leading/branches/nickAirsoft.png',
    text: dayjs().diff('1998-12-28', 'years') + " Jahre alt\nSpartenleiter Airsoft",
    socials: {
      instagram: 'air_nj'
    }
  },
  ELVAN_ELMAS: {
    fullName: 'Elvan Elmas',
    image: '/assets/leading/branches/elvanGaming.png',
    text: dayjs().diff('1998-09-18', 'years') + " Jahre alt\nSpartenleiterin Gaming",
    socials: {
      instagram: 'elvanelmas.98'
    }
  }
};